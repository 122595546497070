import React, { useState, useEffect } from "react"
import ContentLoader, { Facebook } from "react-content-loader"
import { jsx, css } from "@emotion/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import GoogleMap from "../GoogleMap"

export default function Kontaktai(props) {
  const [loadMaps, setLoadMaps] = useState(false)
  const showTitle = props.showTitle

  function countdownLoadMaps() {
    return setTimeout(() => {
      setLoadMaps(true)
    }, 4000)
  }

  useEffect(() => {
    countdownLoadMaps()
  })

  function KontaktaiTitle(props) {
    const showTitle = props.showTitle
    if (showTitle) {
      return <h3 css={contactsTitle}>Kontaktai</h3>
    } else {
      return <div></div>
    }
  }

  return (
    <section css={contactsSection}>
      {showTitle && <h3 css={contactsTitle}>Kontaktai</h3>}
      <div css={contactsLayout}>
        <div css={contactsDetails}>
          <h5 css={contactsSmallTitle}>Adresas:</h5>
          <p css={paragraphNewline}>Vaidoto g. 183 (Panemunė), 45392 Kaunas</p>

          <h5 css={contactsSmallTitle}>Darbo laikas: </h5>
          <p css={paragraph}>I-V: 8:30-18:00</p>
          <p css={paragraphNewline}>VI-VII: nedirbame</p>

          <p css={boldStart}>
            Tel. nr:{" "}
            <a css={reservationSubtitlePhoneButton} href="tel:+37068845247">
              +37068845247
            </a>
          </p>
          <p css={boldBottom}>
            El. paštas:{" "}
            <a css={email} href="mailto:administracija@vaidoto183.lt">
              administracija@vaidoto183.lt
            </a>
          </p>

          <img
            loading="lazy"
            css={contactsImage}
            alt="automobiliu servisas kaune panemuneje"
            src="https://ik.imagekit.io/jaesjef6q7/general-assets/tr:w-420/IMG_20190713_143545_-_Copy_6NH7_44gw.jpg"
          ></img>
        </div>
        {loadMaps === true && <GoogleMap />}
        {loadMaps !== true && (
          <ContentLoader viewBox="0 0 450 460" width={450} height={505}>
            <rect x="0" y="0" rx="5" ry="5" width="450" height="505" />
          </ContentLoader>
        )}
      </div>
    </section>
  )
}

const email = css`    
color: #6c757d;
font-weight: 700;
}`

const boldStart = css`
  font-weight: 700;
  margin-bottom: 0;
`
const boldBottom = css`
  font-weight: 700;
  margin-top: 0;
`

const paragraph = css`
  margin: 0;
`

const paragraphNewline = css`
  margin: 0 0 10px 0;
`

const contactsSmallTitle = css`
margin 0;
font-size: 19px;
font-weight: 700;
color: #6c757d;
font-family: 'Roboto Slab',-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
`

const reservationSubtitlePhoneButton = css`
  background-color: #20e24a;
  border-color: #20e24a;
  display: inline-block;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  font-style: normal;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.1rem 0.2rem;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    background-color: #19c23e !important;
    border-color: #19c23e !important;
    color: #fff;
  }
`

const contactsDetails = css`
padding 0 15px;
flex-shrink: 1;
  min-width: 300px;
  max-width: 450px;
  box-sizing: border-box;
  font-size: 19px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: rgb(108, 117, 125);
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
`

const contactsImage = css`
  width: 100%;
`
const contactsSection = css``
const contactsLayout = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 1200px;
`

const contactsTitle = css`
  margin-top: 50px;
  text-align: center;
  font-size: 37px;
  font-weight: 700;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
`

const benefitCardTitle = css`
  font-size: 24px;
  text-align: center;
  margin: 0 0;
  font-weight: 700;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
`

const benefitCardText = css`
  text-align: center;
  font-size: 16px;
  color: #6c757d;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
`

const benefitIconBackground = css`
  color: #feb836;
  font-size: 124px;
`
