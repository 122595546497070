import React from "react"
import GoogleMapReact from "google-map-react"
import { jsx, css } from "@emotion/core"

const defaultProps = {
  center: {
    lat: 54.860025,
    lng: 23.954911,
  },
  zoom: 14,
}

const AnyReactComponent = ({ text }) => <div>{text}</div>

const GoogleMap = () => (
  <div css={map}>
    <GoogleMapReact
      // bootstrapURLKeys={{ key: 'AlzaSyBRKoTpCZsUXfcXyxHxoK-PpXMHYwfqs8o' }}
      bootstrapURLKeys={{ key: "AIzaSyDNwzsYhvEYp4R8U8CeHn3rYz5pr-OAb68" }}
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
    >
      <AnyReactComponent lat={54.860025} lng={23.954911} text={"Vaidoto183"} />
    </GoogleMapReact>
  </div>
)

const map = css`
margin-top:15px;
flex: 1;
position: absolute;
min-width: 350px;
max-width: 450px;
position: relative;
padding 0 15px;
height: 476px;
`

export default GoogleMap
